.clsYearDropdown {
    position: relative;
    display: inline-block;
    margin-left: 0px;
    // &:hover{
    //   .clsDropdownContent{
    //     display: block;
    //   }
    // } 

    &.clsMonthDropdown{
      font-weight: 800;
      margin-left: 0px;
    }

    .clsDropbtn {  
      padding: 5px 10px;
      font-size: 22px;
      font-weight: 500;
      color: #128292;
      border-radius: 8px;
      line-height: 22px;
      border: none;
      background-color: transparent;
      &:hover{
        background-color: #DFE1E4;
      }
      &:focus{
        background-color: #BCC1C7; 
      }
      &.active{
        background-color: #128292;
        color: #fff;
      }
    }

    .clsDropdownContent {
      display: block;
      // display: none;
      position: absolute;
      background-color: #fff;
      border: solid 2px #128292;
      border-radius: 12px;
      padding: 6px 3px 6px 6px;
      // width: 75px; 
      // min-width: 160px;
      box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.1);
      margin-top: 8px;
      z-index: 9;
      .clsInnerScroll{
        padding-right: 3px;
        max-height: 264px;
        overflow-y: auto;
        &::-webkit-scrollbar{
          width: 5px;
        }
      }

      a {
        color: #3C4654;
        padding: 5px 7px;
        text-decoration: none !important;
        display: block;
        font-size: 16px;
        font-weight: 600;
        border-radius: 8px;
        line-height: 19px;
        outline: none;
        cursor: pointer;
        &:hover{
          background-color: #DFE1E4 
        }
        &:focus{
          background-color: #BCC1C7;  
        }

        &.active{
          background-color: #128292;
          color: #fff;
        }
      }
    } 
  }
  
 
  
  
  
