// style.scss
@use 'sass:math';

.root {
  position: relative;

  .react-datepicker__input-container {
    display: block;
  }

  .react-datepicker__close-icon {
    display: none;
  }

  /* Change color of days in the calendar */
  .react-datepicker__day {
    color: red;
    /* Change the color to your desired color */
  }

  /* Change color of selected day */
  .react-datepicker__day--selected {
    background-color: green;
    /* Change the background color to your desired color */
    color: white;
    /* Change the text color to ensure visibility */
  }

}

.icon {
  $size: 15px;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 9;

  img {
    height: $size;
    width: $size;
  }
}

// .myContainer {
//   padding: 16px;
//   background: #17c312;
//   color: #fff;

//   > div:first-child {
//     background: #f0f0f0;
//   }
// }

.clsCustomHeader { 
  padding: 15px 10px;
  .clsTopHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;

    .clsArrows {
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;
      width: 25px;
      height: 25px;
      border-radius: 999px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
  
      &.clsRightArrow {
        transform: rotate(180deg);
      }
  
      &:hover {
        background-color: #DFE1E4;
      }
  
      &:focus {
        background-color: #BCC1C7;
      }
    }
  
    .clsDateTxt {
      font-size: 20px;
      line-height: 20px;
      color: #3C4654;
      font-weight: 550;
    }
  }
.clsBotHeader{
.clsTodayButton{
padding: 8px 14px;
font-size: 14px;
font-weight: 550;
color: #128292;
border-radius: 999px;
&:hover{
  background-color: #DFE1E4;
}
&:focus{
  background-color: #BCC1C7;
}
}
}
  
}

.clsFillterDatePickerBox {
  position: relative;
  margin-right: 2px;
  width: 100%;

  input {
    border: 0 !important;
    box-shadow: 0px 0px 0px 1px #dfe1e4 inset;
    color: #3c4654; 
    padding: 10px 15px;
    height: 44px;
    border-radius: 0px;
    width: 100%;
    background: #fff;
    transition: all 150ms;
    font-size: 16px;
    font-weight: 500;
    z-index: 6;
    position: relative;
    overflow: hidden;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    &::placeholder {
      color: #99a0aa;
    }

    &:focus {
      box-shadow: 0px 0px 0px 2px #128292 inset; 
    }
  }

  &:first-child {
    input {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      padding-left: 30px;

      &:focus {
        border-bottom-left-radius: 0px;
      }
    }
  }

  &:last-child {
    margin-right: 0px;

    input {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;

      &:focus {
        border-bottom-right-radius: 0px;
      }
    }

    .icon {
      display: none;
    }
  }
}